import React from 'react';
import { useTranslation } from 'react-i18next';

const ContextSection: React.FC = () => {
  const { t } = useTranslation('translation');
  const listDifficulties = [
    { id: 1, text: t('ctx-card1') },
    {
      id: 2,
      text: t('ctx-card2'),
    },
    {
      id: 3,
      text: t('ctx-card3'),
    },
    {
      id: 4,
      text: t('ctx-card4'),
    },
    {
      id: 5,
      text: t('ctx-card5'),
    },
  ];

  return (
    <div className="">
      <h2 className="heading ">{t('t-contexte')}</h2>
      <h2 className="text-2xl font-bold mb-4 text-center">
        {t('ctx-accroche')}
      </h2>
      <div className="w-[80%] pt-[2rem] font-mulish mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem]">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem]  pt-[2rem] mx-auto"> */}
        {listDifficulties.map((i) => (
          <div
            key={i.id}
            data-aos="fade-up"
            data-aos-delay="300"
            className="flex justify-center items-center"
          >
            <div className="transform cursor-pointer hover:-translate-y-6 transition-all duration-200 relative p-2 w-full h-[200px] md:h-[250px] bg-[#108236] rounded-2xl flex justify-center items-center">
              <p className="text-[#d3d2d2] text-center">{i.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContextSection;
