import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const ProjetSection: React.FC = () => {
  const { t } = useTranslation('translation');
  const listProjet = [
    {
      id: 1,
      title: t('prj-title1'),
      description: t('prj-card1'),
      iconName: 'fa-cow',
    },
    {
      id: 2,
      title: t('prj-title2'),
      description: t('prj-card2'),
      iconName: 'location-dot',
    },
    {
      id: 3,
      title: t('prj-title3'),
      description: t('prj-card3'),
      iconName: 'fa-file',
    },
  ];
  return (
    <div className="pt-[4rem] md:pt-[8rem] pb-[5rem]">
      <p className="heading">{t('t-projet')}</p>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white"> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  w-[80%] mx-auto justify-items-center items-center gap-[3rem] mt-[4rem] text-white">
        {listProjet.map((i) => (
          <div
            key={i.id}
            data-aos="zoom-in"
            data-aos-delay="300"
            className="flex justify-center text-center"
          >
            <div className="bg-[#108236] hover:scale-110 transform transition-all duration-300    text-center p-[2rem] w-full h-[400px] md:h-[400px]">
              {/* <i className={`fab ${i.iconName} fa-lg`}></i> */}
              <h2 className="text-lg sm:text-xl mt-[1.5rem] mb-[0.5rem] uppercase  font-semibold">
                {i.title}
              </h2>
              <p className="font-mulish text-lg sm:text-xl  text-[#d3d2d2] font-normal">
                {i.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjetSection;
