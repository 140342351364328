import React, { useState, useRef, useEffect } from 'react';
import { /*AiOutlineClose,*/ AiOutlineMenu } from 'react-icons/ai';
import logoImg from 'asset/logoV1.png';
import APPCOLORS from 'constant/color';
import MenuComponent from 'components/navbar/menuComponent';

const Navbar: React.FC = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);
  const navRef = useRef<HTMLUListElement>(null);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };
  const closeNav = () => {
    setNav(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        nav &&
        navRef.current &&
        !navRef.current.contains(event.target as Node)
      ) {
        closeNav();
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [nav]);
  // Array containing navigation items

  return (
    <div
      style={{ backgroundColor: APPCOLORS.green1 }}
      className={`fixed top-0 w-full  flex justify-between items-center h-24  mx-auto p-5 text-white  z-50`}
    >
      {/* Logo */}
      {!nav && (
        <img
          src={logoImg}
          // alt="Betaclic"
          className="h-20 w-auto object-contain"
          alt="Betaclic"
          width="80"
          height="40"
          title="Betaclic"
          loading="eager"
        />
      )}

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden mr-4">
        {/*nav ? (
          <AiOutlineClose size={20} color="black" />
        ) : (
          <AiOutlineMenu size={20} color="black" />
        )*/}

        {!nav && <AiOutlineMenu size={20} color="black" />}
      </div>

      <MenuComponent nav={nav} navRef={navRef} closeNav={closeNav} />
    </div>
  );
};

export default Navbar;
