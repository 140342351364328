import React from 'react';
import impact1 from 'asset/impact1.jpg';
import impact2 from 'asset/impact2.jpg';
import { useTranslation } from 'react-i18next';

const ImpactSection: React.FC = () => {
  const { t } = useTranslation('translation');
  const listImpact = [
    {
      id: 1,
      title: t('imp-title1'),
      description: '',
      // description: t('imp-card1'),
      iconName: 'fa-cow',
      img: impact1,
    },
    {
      id: 2,
      title: t('imp-title2'),
      description: t('imp-card2'),
      iconName: 'fa-cow',
      img: impact2,
    },
  ];
  return (
    <div className=" w-full pb-[3rem] pt-[4rem] md:pt-[8rem]">
      <h2 className="heading">{t('t-impact')}</h2>
      {listImpact.map((i) => (
        <div
          key={i.id}
          className="grid grid-cols-1 md:grid-cols-2 w-[80%] mx-auto gap-[3rem] items-center"
        >
          <div>
            <div className="mb-[3rem] flex flex-col items-center justify-center">
              <span className="w-[100px] hidden md:block h-[5px] bg-black rounded-sm mb-[1rem]"></span>
              <h2 className="text-lg sm:text-xl mt-[1.5rem] mb-[0.8rem] uppercase font-semibold text-center text-[#108236]">
                {i.title}
              </h2>
              <p className="font-mulish text-lg sm:text-xl text-gray-600 font-normal text-center">
                {i.description}
              </p>
            </div>
          </div>

          <div
            data-aos="fade-left"
            data-aos-delay="300"
            className="lg:w-[500px] mx-auto md:mx-0 mt-[0.25rem]  md-mt-[2rem] lg:mt-0 lg:h-[500px] w-[300px] h-[300px] relative right-[2rem] "
          >
            <img
              src={`${i.img}`} // Remplacez par l'URL de votre image
              // alt="impact"
              // className="w-full h-auto object-cover"
              className="relative z-[11] w-[100%] h-[70%] object-cover rounded-md" // Ajustez la hauteur pour les écrans larges
              alt="Betaclic"
              width="80"
              height="40"
              title="Betaclic"
              loading="lazy"
            />

            <div className="absolute w-[100%] h-[60%] z-[10] bg-[#108236] top-[2rem] right-[-2rem]"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImpactSection;
