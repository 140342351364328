import React, { useEffect, useState } from 'react';
import bgImg from 'asset/banner.jpg';
import APPCOLORS from 'constant/color';
// import logoImg from 'asset/logoWithSlogan.svg';
import logo_fr from 'asset/Logo/logo_fr.svg';
import logo_ar from 'asset/Logo/logo_ar.svg';
import logo_en from 'asset/Logo/logo_en.svg';
import { useTranslation } from 'react-i18next';

const Banner: React.FC = () => {
  const { t, i18n } = useTranslation('translation');
  const [image, setImage] = useState(logo_fr);
  const current = i18n.language;
  useEffect(() => {
    const getPicture = () => {
      switch (current) {
        case 'en':
          setImage(logo_en);
          break;
        case 'fr':
          setImage(logo_fr);
          break;
        case 'ar':
          setImage(logo_ar);
          break;
        default:
          break;
      }
    };
    getPicture();
  }, [current]);

  /* return (
    <div className="mt-20">
      <header
        className="text-center py-10 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="bg-black bg-opacity-80 py-20 px-4">
          <h2
            className={`text-4xl font-bold `}
            style={{ color: APPCOLORS.primary }}
          >
            L’innovation et la technologie au service du développement
          </h2>
          <p className="text-black mt-4">
            Solutions Digitales | Agricultuure & Elevage
          </p>
        </div>
      </header>
    </div>
  ); */
  return (
    <div className="flex flex-col items-center  mt-20">
      {/* <img
        src={`${bgImg}`} // Remplacez par l'URL de votre image de bannière
        alt="Bannière"
        className="w-60 h-60 object-cover"
      /> */}
      <div className="w-full">
        <img
          src={`${bgImg}`} // Remplacez par l'URL de votre image
          className="w-full h-auto object-cover" // Ajustez la hauteur pour les écrans larges
          alt="Betaclic"
          width="80"
          height="40"
          title="Betaclic"
          loading="eager"
        />
      </div>
      <div className="flex flex-col items-center p-4">
        <h1
          style={{ color: APPCOLORS.primary }}
          className="text-3xl sm:text-4xl font-bold mb-2 mt-2 text-center"
        >
          {t('title')}
        </h1>
        <p className="font-mulish text-lg sm:text-xl text-gray-600 mb-4 text-center">
          {t('text')}
        </p>
      </div>
      <img
        src={image}
        //  className="h-40 w-30 object-contain"
        className="w-[60%] md:w-[40%] lg:w-[25%] h-[25%] object-contain "
        alt="Betaclic"
        title="Betaclic"
        loading="lazy"
      />
    </div>
  );
};

export default Banner;
